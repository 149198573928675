.about-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-cards-container {
  width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.about-card {
  position: relative;
  width: 40vw;
  height: 40vh;
  margin: 8px 16px;
  background: linear-gradient(118deg, #814AF6, #41257b);
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-card-img-container img{
  height: 175px;
  width: auto;
  margin-bottom: 30px;
}

.about-card-title-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-weight: 600;
  font-size: 1.4vw;
  font-family: 'Barlow', sans-serif;
  background: linear-gradient(118deg, #1b2631,#0b0f14);
  color: #e6dbfd;
  text-align: center;
  padding: 10px;
  border-radius: 0 0 7px 7px;
}

