.card-on-zoom-tb {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  filter: hue-rotate(-280deg);
}

.card-on-zoom-tb h1 {
  width: 100%;
  font-size: calc(12px + 5vw);
  font-weight: 600;
  color: #f2edfe;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3);
}

.card-on-zoom-tb p {
  width: 500px;
  font-size: calc(12px + 1.5vw);
  border-radius: 7px;
  padding: 10px 30px;
  background: linear-gradient(118deg, #814af6cc, #341e62cc);
  color: #eef0f1;
  font-weight: 600;
  font-family: "Barlow", sans-serif;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
}
