.about-content-container-mb {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .about-cards-container-mb {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;
  }
  
  .about-card-mb {
    position: relative;
    margin: 8px 16px;
    background: linear-gradient(118deg, #814AF6, #41257b);
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .about-card-img-container-mb img{
    height: 150px;
    width: auto;
    padding: 30px 20px;
    margin-bottom: 30px;
  }
  
  .about-card-title-container-mb {
    position: absolute;
    bottom: 0;
    width: 100%;
    font-weight: 600;
    font-size: 1em;
    font-family: 'Barlow', sans-serif;
    background: linear-gradient(118deg, #1b2631,#0b0f14);
    color: #e6dbfd;
    text-align: center;
    padding: 10px;
    border-radius: 0 0 7px 7px;
  }
  
  