@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "./header/header.css";
@import "./header/header-tb.css";
@import "./header/header-mb.css";

@import "./zoom/zoom.css";
@import "./zoom/zoom-tb.css";
@import "./zoom/zoom-mb.css";

@import "./about/about.css";
@import "./about/about-tb.css";
@import "./about/about-mb.css";

@import "./projects/projects.css";
@import "./projects/projects-tb.css";
@import "./projects/projects-mb.css";

@import "./investments/investments.css";
@import "./investments/investments-tb.css";
@import "./investments/investments-mb.css";

@import "./team/team.css";
@import "./team/team-tb.css";
@import "./team/team-mb.css";

@import "./footer/footer.css";
@import "./footer/footer-tb.css";
@import "./footer/footer-mb.css";

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  -moz-tab-size: 4;
  tab-size: 4;
}


html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: #10171D;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1200 800'%3E%3Cdefs%3E%3CradialGradient id='a' cx='0' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23161f27'/%3E%3Cstop offset='1' stop-color='%23161f27' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='1200' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%230c1116'/%3E%3Cstop offset='1' stop-color='%230c1116' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='c' cx='600' cy='0' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23111820'/%3E%3Cstop offset='1' stop-color='%23111820' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='d' cx='600' cy='800' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%2310171D'/%3E%3Cstop offset='1' stop-color='%2310171D' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='e' cx='0' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%231B2631'/%3E%3Cstop offset='1' stop-color='%231B2631' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='f' cx='1200' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23080B0F'/%3E%3Cstop offset='1' stop-color='%23080B0F' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='1200' height='800'/%3E%3Crect fill='url(%23b)' width='1200' height='800'/%3E%3Crect fill='url(%23c)' width='1200' height='800'/%3E%3Crect fill='url(%23d)' width='1200' height='800'/%3E%3Crect fill='url(%23e)' width='1200' height='800'/%3E%3Crect fill='url(%23f)' width='1200' height='800'/%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}

.section-investment {
  padding-top: 100px;
  padding-bottom: 100px;
  background-size: cover;
}

code,
kbd,
samp,
pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, "Liberation Mono", Menlo,
    monospace; /* 1 */
  font-size: 1em; /* 2 */
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  margin: 0; /* 2 */
}

/**
Remove the inheritance of text transform in Edge and Firefox.
1. Remove the inheritance of text transform in Firefox.
*/

button,
select {
  /* 1 */
  text-transform: none;
}

*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  border-width: 0; /* 2 */
  text-decoration: inherit;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

video {
  max-width: 100%;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.container {
  width: 100%;
}

.section-about {
  padding-top: 100px;
  padding-bottom: 100px;
}

.section-projects {
  padding-top: 100px;
  padding-bottom: 100px;
}

.section-investments {
  padding-top: 100px;
  padding-bottom: 100px;
}

.section-team {
  padding-top: 100px;
  padding-bottom: 100px;
  background-attachment: fixed;
  background-size: cover;
}

.section-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  padding-left: 4px;
  padding-right: 4px;
  z-index: 3;
}

.section-container-inside {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin-bottom: 24px;
  z-index: 3;
}

.section-content {
  width: 100%;
  padding: 4px;
}

.content-title {
  position: relative;
  justify-content: center;
  align-items: center;
}

.content-title h2 {
  position: relative;
  font-size: 4em;
  color: #8149f5;
  font-weight: 400;
  margin-left: auto;
  margin-right: auto;
  font-family: "Oswald", sans-serif;
  filter: drop-shadow(0 0 1px #8249f513) drop-shadow(0 0 2px #8249f534)
    drop-shadow(0 0 3px #8249f567);
  z-index: 3;
}

.castles-section-frame {
  position: relative;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
}

.frame-img {
  position: relative;
}


/* animates box shadow glow effect */
@keyframes glow {
  0%,
  100% {
    box-shadow: 0 0 20px 5px #8149f5;
  }
  50% {
    box-shadow: 0 0 16px 8px #8149f5;
  }
}

.title-container {
  margin-top: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.first-title {
  font-size: 2.5em;
  color: #e6dbfd;
  font-weight: 800;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Barlow', sans-serif;
  text-align: center;
  margin-bottom: 20px;
  letter-spacing: 0.05em;
}

.second-title {
  font-size: 1.6em;
  padding: 10px 20px;
  border-radius: 7px;
  background: linear-gradient(118deg, #814AF6, #41257b);
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
  color: #eef0f1;
  width: fit-content;
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Barlow', sans-serif;
  text-align: center;
}

.description-container {
  text-align: center;
}

.description {
  margin-right: auto;
  margin-left: auto;
  font-family: 'Barlow', sans-serif;
  font-size: 1.6em;
  color: #eef0f1;
  width: 40vw;
  font-weight: 400;
  margin-top: 20px;
}

.title-container-tb {
  margin-top: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.first-title-tb {
  font-size: 2em;
  color: #e6dbfd;
  font-weight: 800;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Barlow', sans-serif;
  text-align: center;
  margin-bottom: 20px;
  letter-spacing: 0.05em;
}

.second-title-tb {
  font-size: 1em;
  padding: 10px 20px;
  margin: 0 50px;
  border-radius: 7px;
  background: linear-gradient(118deg, #814AF6, #41257b);
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
  color: #eef0f1;
  font-weight: 600;
  font-family: 'Barlow', sans-serif;
  text-align: center;
}

.description-container-tb {
  text-align: center;
}

.description-tb {
  margin-right: auto;
  margin-left: auto;
  font-family: 'Barlow', sans-serif;
  font-size: 1em;
  width: 600px;
  color: #eef0f1;
  font-weight: 600;
  margin-top: 20px;
} 

.title-container-mb {
  margin-top: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.first-title-mb {
  font-size: 2em;
  color: #e6dbfd;
  font-weight: 800;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Barlow', sans-serif;
  text-align: center;
  margin-bottom: 20px;
  letter-spacing: 0.05em;
}

.second-title-mb {
  font-size: 1.2em;
  padding: 10px 20px;
  margin: 0 50px;
  border-radius: 7px;
  background: linear-gradient(118deg, #814AF6, #41257b);
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
  color: #eef0f1;
  font-weight: 600;
  font-family: 'Barlow', sans-serif;
  text-align: center;
}

.description-container-mb {
  text-align: center;
}

.description-mb {
  margin-right: auto;
  margin-left: auto;
  font-family: 'Barlow', sans-serif;
  font-size: 1em;
  margin: 0 50px;
  color: #eef0f1;
  font-weight: 600;
  margin-top: 20px;
} 

/* Header */
.large-header {
	position: relative;
	width: 100%;
	background: #333;
	overflow: hidden;
	background-size: cover;
	background-position: center center;
	z-index: 1;
}

#large-header {
  background-color: #10171D;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1200 800'%3E%3Cdefs%3E%3CradialGradient id='a' cx='0' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23161f27'/%3E%3Cstop offset='1' stop-color='%23161f27' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='1200' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%230c1116'/%3E%3Cstop offset='1' stop-color='%230c1116' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='c' cx='600' cy='0' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23111820'/%3E%3Cstop offset='1' stop-color='%23111820' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='d' cx='600' cy='800' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%2310171D'/%3E%3Cstop offset='1' stop-color='%2310171D' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='e' cx='0' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%231B2631'/%3E%3Cstop offset='1' stop-color='%231B2631' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='f' cx='1200' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23080B0F'/%3E%3Cstop offset='1' stop-color='%23080B0F' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='1200' height='800'/%3E%3Crect fill='url(%23b)' width='1200' height='800'/%3E%3Crect fill='url(%23c)' width='1200' height='800'/%3E%3Crect fill='url(%23d)' width='1200' height='800'/%3E%3Crect fill='url(%23e)' width='1200' height='800'/%3E%3Crect fill='url(%23f)' width='1200' height='800'/%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}

.main-title {
	position: absolute;
	margin: 0;
	padding: 0;
	color: #f9f1e9;
	text-align: center;
	top: 50%;
	left: 50%;
	-webkit-transform: translate3d(-50%,-50%,0);
	transform: translate3d(-50%,-50%,0);
}

.demo-1 .main-title {
	text-transform: uppercase;
	font-size: 4.2em;
	letter-spacing: 0.1em;
}

.main-title .thin {
	font-weight: 200;
}

@media only screen and (max-width : 768px) {
	.demo-1 .main-title {
		font-size: 3em;
	}
}

.fab-container {
  visibility: hidden;
  position: fixed;
  bottom: 32px;
  right: 32px;
  z-index: 1;
}

.fab-content {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 7px;
  background: #4d2c94;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
}

.fab-content i {
  font-size: 1.2em;
  color: #eef0f1 !important;
}

.hidden {
  visibility: hidden !important;
}

.show {
  visibility: visible !important;
}