.footer-relative-mb {
  position: relative;
}
.footer-social-mb {
  bottom: 35px;
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-social-mb:before {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 35px;
  z-index: 1;
  backdrop-filter: blur(5px);
}
.footer-social-mb .color {
  position: absolute;
  filter: blur(150px);
}

.footer-social-mb ul {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  z-index: 2;
}
.footer-social-mb ul li {
  position: relative;
  list-style: none;
  margin: 10px;
}
.footer-social-mb ul li a {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  display: inline-block;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #eef0f1;
  background: linear-gradient(#814af6cc, #4d2c94cc);
  font-size: 1.2em;
  box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
  transition: 0.4s;
  overflow: hidden;
}

.footer-copyright-mb {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  padding-top: 10px;
  padding-right: 15px;
  padding-bottom: 10px;
  padding-left: 15px;
  margin-bottom: 25px;
}

.footer-copyright-text-mb {
  font-size: 1em;
  color: #f2edfe;
  backdrop-filter: blur(10px);
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  filter: drop-shadow(0 0 1px #17020c);
}

.area-container-mb {
  display: flex;
  z-index: 2;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text-content-mb {
  text-align: center;
  font-family: "Barlow", sans-serif;
  margin: 50px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-content-mb h3 {
  font-size: 3.5em;
  font-weight: 900;
  color: #e6dbfd;
  font-family: "Barlow", sans-serif;
}

.text-content-mb .dont-miss-mb {
  border-radius: 7px;
  background: linear-gradient(118deg, #814af6cc, #41257bcc);
  text-align: center;
  color: #f2edfe;
  font-size: 1.2em;
  font-weight: 600;
  padding: 10px 20px;
  font-family: "Barlow", sans-serif;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
}

.text-content-mb .mail-mb {
  margin-top: 10px;
  font-size: 1.3em;
  font-weight: 600;
  color: #eef0f1;
  font-family: "Barlow", sans-serif;
}
