.navbar {
  background: transparent;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
  max-width: 100%;
  display: -webkit-flex;
  display: flex;
  padding: 0;
}

.transparentify {
  background: linear-gradient(118deg, #814AF6, #341e62);
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
}

.transparentify .navbar-logo-text{
  width: 200px;
}

.transparentify a {
  font-size: 1em;
}

.navbar-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.navbar-contentify {
  padding: 0.8vw 1.6vw;
  margin: 0 12vw;
  border-radius: 0 0 7px 7px;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-inside {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.navbar-logo-text {
  width: 250px;
  height: auto;
  transition: 0.2s;
}

.navbar-logo-text:hover {
  transform: scale(1.1, 1.1);
  transition: 0.2s;
}

.nav-links {
  height: auto;
}

.menu {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around !important;
  align-items: center;
  list-style: none;
}

.menu li{
  margin: 0 10px
}

.menu a {
  position: relative;
  margin: auto;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-size: 1.3em;
  text-decoration: initial;
  text-transform: capitalize;
  text-align: center;
  color: #eef0f1;
  font-weight: 800;
  font-family: 'Barlow', sans-serif;
  border-radius: 7px;
}
.menu a:hover {
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 7px;
}

.menu-item-alt {
  text-decoration: none;
  position: relative;
  overflow: hidden;
}

.menu-item-alt:hover {
  background: linear-gradient(118deg, #814AF6, #341e62);
  box-shadow: 1px 1px 25px 10px rgba(129, 74, 246, 0.4);
}

.menu-item-alt:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(129, 74, 246, 0.4),
    transparent
  );
  transition: all 650ms;
}

.menu-item-alt:hover:before {
  left: 100%;
}

.transparentify .menu-item-alt:hover {
  background: linear-gradient(118deg, #1b2631,#0b0f14);
  box-shadow: 1px 1px 25px 10px rgba(27, 38, 49, 0.4);
}

.transparentify .menu-item-alt:before {
  background: linear-gradient(
    120deg,
    transparent,
    rgba(27, 38, 49, 0.4),
    transparent
  );
}
