.navbar-mb {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
  max-width: 100%;
  display: -webkit-flex;
  display: flex;
  padding: 0;
}

.navbar-container-mb {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.navbar-contentify-mb {
  background: linear-gradient(118deg, #814af6, #341e62);
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
  padding: 1rem 1.5rem;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-inside {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.navbar-logo-text-mb {
  width: 10rem;
  height: auto;
  transition: 0.2s;
}

.navbar-menu-mb a {
}

.navbar-menu-mb a i {
  color: #eef0f1;
  font-size: 1.2rem;
}

.side-nav-mb {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.side-nav-open-mb {
  width: 250px;
}

.side-nav-mb a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #eef0f1;
  display: block;
  transition: 0.3s;
}

.nav-links-mb {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-mb {
  margin-top: 50px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  list-style: none;
}

.menu-mb li{
  margin: 0 10px
}

.menu-mb a {
  position: relative;
  margin: auto;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-size: 1.3em;
  text-decoration: initial;
  text-transform: capitalize;
  text-align: start;
  color: #eef0f1;
  font-weight: 800;
  font-family: 'Barlow', sans-serif;
  border-radius: 7px;
}