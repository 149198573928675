/* Global CSS for Investment Section */

.investment-assets-container {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.investment-assets-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  width: 60vw;
  justify-content: space-around;
}

.asset-card {
  position: relative;
  width: 160px;
  height: 240px;
  margin: 30px 20px;
  border-radius: 7px;
  transition: 0.2s;
  display: flex;
  justify-content: flex-start;
  padding-top: 24px;
  flex-direction: column;
  align-items: center;
}

.asset-img {
  width: 120px;
  height: 120px;
  padding: 10px;
  margin: 0 auto;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.asset-img-circle {
  width: 120px;
  height: 120px;
  padding: 10px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.details-container-for-asset-card {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.asset-card .asset-name {
  font-weight: 800;
  font-size: 1.2em;
  text-align: center;
  color: #cccccc;
  font-family: 'Barlow', sans-serif;
}

.asset-card .type-container {
  position: absolute;
  margin-top: 10px;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.asset-card p {
  color: #8149f5;
  font-family: 'Barlow', sans-serif;
  font-size: 1em;
  font-weight: 600;
  background: linear-gradient(118deg, #814AF6, #41257b);
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
  color: #eef0f1;
  border-radius: 7px;
  padding: 6px 12px;
}

.asset-card:hover p {
    background: #e6dbfd;
    color: #5a34ac;
}

.asset-card:hover {
  transition: 0.3s;
  border-radius: 7px;
  background: linear-gradient(118deg, #814AF6cc, #41257bcc);
}

.asset-card:hover img{
  filter: none;
}

.asset-card:hover .asset-name {
  color: #eef0f1;
}

