.card-on-zoom-container-mb {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  filter: hue-rotate(-280deg);
}

.card-on-zoom-mb {
    margin: 0 auto;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
  text-align: start;
}

.card-on-zoom-mb .deco-title {
  font-size: 5rem;
  font-weight: 600;
  color: #f2edfe;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3);
}