.section-team-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.core-team-container {
  padding-top: 100px;
}

.team-member {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 100px 0;
  width: 75vw;
}

.team-member-card {
  position: relative;
  width: 20rem;
  height: 40rem;
  margin: 50px 16px;
  background: linear-gradient(118deg, #1b2631,#0b0f14);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.team-member-card:hover {
  background: linear-gradient(118deg, #814AF6, #341e62);
}

.team-member-card:hover .team-member-img-container img{
  background: #d9c9fc;
}

.team-member-card:hover .team-member-details-container h3 {
  color: #eef0f1;
}

.team-member-card:hover .team-member-details-container span {
  background: #e6dbfd;
  color: #5a34ac;
}

.team-member-card:hover .team-member-details-container p {
  color: #d9c9fc;
}

.team-member-details-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 3rem;
}

.team-member-img-container {
  position: absolute;
  width: 100%;
  top: -60px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-member .team-member-img-container img {
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
  background: linear-gradient(118deg, #814af6, #673bc5);
  width: 120px;
  height: auto;
  border-radius: 50%;

  object-fit: cover;
}

.team-member .team-member-details-container h3 {
  color: #eef0f1;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 800;
  text-align: center;
  margin: 20px 0 10px;
  font-size: 1.4em;
  font-family: 'Barlow', sans-serif;
}

.team-member .team-member-details-container span {
  background: linear-gradient(118deg, #814af6, #673bc5);
  font-weight: 700;
  font-family: 'Barlow', sans-serif;
  font-size: 1.2em;
  border-radius: 7px;
  margin: 10px;
  color: #e6dbfd;
  padding: 8px 16px;
}

.team-member .team-member-details-container p {
  font-weight: 600;
  font-family: 'Barlow', sans-serif;
  font-size: 1.2em;
  border-radius: 7px;
  color: #e6dbfd;
  margin: 10px;
  padding: 20px;
  text-align: left;
}

.team-member .team-member-details-container .linkedn-container {
  position: absolute;
  bottom: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-member .team-member-details-container .linkedn-container a{
  visibility: hidden;
  width: 2rem;
  height: 2rem;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-member-card:hover .linkedn-container a {
  visibility: visible;
  background: #e6dbfd;
}

.team-member-card:hover .linkedn-container a i {
  color: #5a34ac;
  font-size: 1em;
}
