/* Global CSS for Projects Section */

.section-project-container {
  margin-top: 100px;
}

.project-assets-container {
  margin-top: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.project-card {
  display: inline-block;
  position: relative;
  width: 400px;
  min-width: 400px;
  height: 400px;
  border-radius: 30px;
  overflow:hidden;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.3);
  margin: 20px;
}

.project-card-profile-img {
  width: 100%;
  height: 70%;
}

.project-card-description-bk {
  background: linear-gradient(118deg, #814AF6, #41257b);
  border-radius: 30px;
  position: absolute;
  top: 55%;
  left: -5px;
  height: 65%;
  width: 108%;
  transform: skew(19deg, -9deg);
}

.first .project-card-description-bk {
  background: linear-gradient(118deg, #00866D, #80c3b6);
}

.second .project-card-description-bk {
  background: linear-gradient(118deg, #2D4999, #981D81);
}

.third .project-card-description-bk {
  background: linear-gradient(118deg, #814AF6, #41257b);
}

.project-card-logo {
  height: 80px;
  width: 80px;
  border-radius: 25px;
  position: absolute;
  bottom: 30%;
  left: 30px;
  overflow:hidden;
  background: linear-gradient(118deg, #1b2631,#0b0f14);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.7);
}

.project-card-logo img {
  height: 100%;
}

.ravelin-logo-img {
  padding: 13px;
  padding-left: 15px;
}

.project-card-learn-more {
  border-radius: 50%;
  position: absolute;
  bottom: 35%;
  right: 30px;
  overflow:hidden;
  background: linear-gradient(118deg, #1b2631,#0b0f14);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  
}

.btn-learn-more {
  padding: 0;
}

.btn-learn-more i{
  color: #F8F9F9;
  font-size: 1.4em;
  margin: 10px 10px 8px 10px;
}

.project-card-description-container {
  position: relative;
  margin-left: 34px;
  margin-top: 15px;
}

.project-card-title{
  font-size: 1.6em;
  font-weight: 700;
  font-family: 'Barlow', sans-serif;
  color: #ECF0F1;
  text-align: start;
  margin-bottom: 10px;
}

.project-card-categories {
  display: flex;
  justify-content: flex-start;
  text-align: start;
  align-items: center;
}

.project-card-category{
  font-size: 1em;
  font-weight: 700;
  font-family: 'Barlow', sans-serif;
  color: #ECF0F1;
  background: linear-gradient(118deg, #1b2631,#0b0f14);
  padding: 7px 14px;
  border-radius: 7px;
  margin: 0 10px 0 0;
}