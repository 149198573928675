.section-team-container-mb {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.core-team-container-mb {
  padding-top: 100px;
}

.team-member-mb {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 100px 30px;
}

.team-member-card-mb {
  position: relative;
  width: 100%;
  margin: 50px 16px;
  background: linear-gradient(118deg, #814af6, #341e62);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 40px;
}

.team-member-details-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 3rem;
}

.team-member-img-container-mb {
  position: absolute;
  width: 100%;
  top: -60px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-member-mb .team-member-details-container-mb {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

.team-member-mb .team-member-img-container-mb img {
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
  background: #d9c9fc;
  width: 120px;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
}

.team-member-mb .team-member-details-container-mb h3 {
  color: #eef0f1;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 800;
  text-align: center;
  margin: 20px 0 10px;
  font-size: 1.4em;
  font-family: "Barlow", sans-serif;
}

.team-member-mb .team-member-details-container-mb span {
  background: #e6dbfd;
  color: #5a34ac;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  font-size: 1.2em;
  border-radius: 7px;
  margin: 10px;
  padding: 8px 16px;
}

.team-member-mb .team-member-details-container-mb p {
  font-weight: 600;
  font-family: "Barlow", sans-serif;
  font-size: 1.2em;
  border-radius: 7px;
  color: #e6dbfd;
  margin: 10px;
  padding: 20px;
  text-align: left;
}

.team-member-mb .team-member-details-container-mb .linkedn-container-mb {
  position: absolute;
  bottom: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-member-card-mb .linkedn-container-mb a{
    visibility: visible;
    background: #e6dbfd;
    width: 2rem;
    height: 2rem;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .team-member-card-mb .linkedn-container-mb a i {
    color: #5a34ac;
    font-size: 1em;
  }
