.navbar-tb {
  background: transparent;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
  max-width: 100%;
  display: -webkit-flex;
  display: flex;
  padding: 0;
}

.navbar-container-tb {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.navbar-contentify-tb {
  background: linear-gradient(118deg, #814af6, #341e62);
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
  padding: 0.8vw 1.6vw;
  border-radius: 0 0 7px 7px;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-inside {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.navbar-logo-text-tb {
  width: 250px;
  height: auto;
  transition: 0.2s;
  margin: 10px 20px;
}

.nav-links-tb {
  height: auto;
  margin: 10px 20px;
}

.menu-tb {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around !important;
  align-items: center;
  list-style: none;
}

.menu-tb li {
  margin: 0 10px;
}

.menu-tb a {
  position: relative;
  margin: auto;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-size: 1.1em;
  text-decoration: initial;
  text-transform: capitalize;
  text-align: center;
  color: #eef0f1;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  border-radius: 7px;
}

.menu-tb .menu-item-alt:hover {
    background: linear-gradient(118deg, #1b2631,#0b0f14);
    box-shadow: 1px 1px 25px 10px rgba(27, 38, 49, 0.4);
  }
