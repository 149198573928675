@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.navbar {
  background: transparent;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
  max-width: 100%;
  display: -webkit-flex;
  display: flex;
  padding: 0;
}

.transparentify {
  background: linear-gradient(118deg, #814AF6, #341e62);
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
}

.transparentify .navbar-logo-text{
  width: 200px;
}

.transparentify a {
  font-size: 1em;
}

.navbar-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.navbar-contentify {
  padding: 0.8vw 1.6vw;
  margin: 0 12vw;
  border-radius: 0 0 7px 7px;
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.container-inside {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.navbar-logo-text {
  width: 250px;
  height: auto;
  transition: 0.2s;
}

.navbar-logo-text:hover {
  -webkit-transform: scale(1.1, 1.1);
          transform: scale(1.1, 1.1);
  transition: 0.2s;
}

.nav-links {
  height: auto;
}

.menu {
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around !important;
          justify-content: space-around !important;
  -webkit-align-items: center;
          align-items: center;
  list-style: none;
}

.menu li{
  margin: 0 10px
}

.menu a {
  position: relative;
  margin: auto;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-size: 1.3em;
  text-decoration: initial;
  text-transform: capitalize;
  text-align: center;
  color: #eef0f1;
  font-weight: 800;
  font-family: 'Barlow', sans-serif;
  border-radius: 7px;
}
.menu a:hover {
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 7px;
}

.menu-item-alt {
  text-decoration: none;
  position: relative;
  overflow: hidden;
}

.menu-item-alt:hover {
  background: linear-gradient(118deg, #814AF6, #341e62);
  box-shadow: 1px 1px 25px 10px rgba(129, 74, 246, 0.4);
}

.menu-item-alt:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(129, 74, 246, 0.4),
    transparent
  );
  transition: all 650ms;
}

.menu-item-alt:hover:before {
  left: 100%;
}

.transparentify .menu-item-alt:hover {
  background: linear-gradient(118deg, #1b2631,#0b0f14);
  box-shadow: 1px 1px 25px 10px rgba(27, 38, 49, 0.4);
}

.transparentify .menu-item-alt:before {
  background: linear-gradient(
    120deg,
    transparent,
    rgba(27, 38, 49, 0.4),
    transparent
  );
}

.navbar-tb {
  background: transparent;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
  max-width: 100%;
  display: -webkit-flex;
  display: flex;
  padding: 0;
}

.navbar-container-tb {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.navbar-contentify-tb {
  background: linear-gradient(118deg, #814af6, #341e62);
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
  padding: 0.8vw 1.6vw;
  border-radius: 0 0 7px 7px;
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.container-inside {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.navbar-logo-text-tb {
  width: 250px;
  height: auto;
  transition: 0.2s;
  margin: 10px 20px;
}

.nav-links-tb {
  height: auto;
  margin: 10px 20px;
}

.menu-tb {
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around !important;
          justify-content: space-around !important;
  -webkit-align-items: center;
          align-items: center;
  list-style: none;
}

.menu-tb li {
  margin: 0 10px;
}

.menu-tb a {
  position: relative;
  margin: auto;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-size: 1.1em;
  text-decoration: initial;
  text-transform: capitalize;
  text-align: center;
  color: #eef0f1;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  border-radius: 7px;
}

.menu-tb .menu-item-alt:hover {
    background: linear-gradient(118deg, #1b2631,#0b0f14);
    box-shadow: 1px 1px 25px 10px rgba(27, 38, 49, 0.4);
  }

.navbar-mb {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
  max-width: 100%;
  display: -webkit-flex;
  display: flex;
  padding: 0;
}

.navbar-container-mb {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.navbar-contentify-mb {
  background: linear-gradient(118deg, #814af6, #341e62);
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
  padding: 1rem 1.5rem;
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.container-inside {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.navbar-logo-text-mb {
  width: 10rem;
  height: auto;
  transition: 0.2s;
}

.navbar-menu-mb a {
}

.navbar-menu-mb a i {
  color: #eef0f1;
  font-size: 1.2rem;
}

.side-nav-mb {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.side-nav-open-mb {
  width: 250px;
}

.side-nav-mb a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #eef0f1;
  display: block;
  transition: 0.3s;
}

.nav-links-mb {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.menu-mb {
  margin-top: 50px;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-direction: column;
          flex-direction: column;
  list-style: none;
}

.menu-mb li{
  margin: 0 10px
}

.menu-mb a {
  position: relative;
  margin: auto;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-size: 1.3em;
  text-decoration: initial;
  text-transform: capitalize;
  text-align: start;
  color: #eef0f1;
  font-weight: 800;
  font-family: 'Barlow', sans-serif;
  border-radius: 7px;
}
.zoom {
    width: 100%;
    height: 100%;
    -webkit-filter: hue-rotate(280deg);
            filter: hue-rotate(280deg);
    z-index: -1;
  }
  
  .card-on-zoom {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    -webkit-filter: hue-rotate(-280deg);
            filter: hue-rotate(-280deg);
  }
  
  .card-on-zoom h1 {
    width: 100%;
    font-size: calc(12px + 5vw);
    font-weight: 600;
    color: #f2edfe;
    text-shadow: 2px 7px 5px rgba(0,0,0,0.3);
  }
  
  .card-on-zoom p {
    width: 100%;
    font-size: calc(12px + 1.8vw);
    border-radius: 7px;
    padding: 10px 30px;
    background: linear-gradient(118deg, #814AF6cc, #341e62cc);
    color: #eef0f1;
    font-weight: 600;
    font-family: "Barlow", sans-serif;
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
  }
  
  a {
    text-decoration: inherit !important;
  }
  
  .arrow {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    -webkit-filter: hue-rotate(-280deg);
            filter: hue-rotate(-280deg);
    margin-top: -60px;
  }
  
  .arrow i{
    padding-bottom: 100px;
    color: #eef0f1;
    font-size: 3em;
  }
  
  .arrow:hover i {
    color: #814af6;
  
  }
  
  .bounce {
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
  }
  
  @-webkit-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    40% {
      -webkit-transform: translateY(-30px);
              transform: translateY(-30px);
    }
    60% {
      -webkit-transform: translateY(-15px);
              transform: translateY(-15px);
    }
  }
  
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    40% {
      -webkit-transform: translateY(-30px);
              transform: translateY(-30px);
    }
    60% {
      -webkit-transform: translateY(-15px);
              transform: translateY(-15px);
    }
  }
.card-on-zoom-tb {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  -webkit-filter: hue-rotate(-280deg);
          filter: hue-rotate(-280deg);
}

.card-on-zoom-tb h1 {
  width: 100%;
  font-size: calc(12px + 5vw);
  font-weight: 600;
  color: #f2edfe;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3);
}

.card-on-zoom-tb p {
  width: 500px;
  font-size: calc(12px + 1.5vw);
  border-radius: 7px;
  padding: 10px 30px;
  background: linear-gradient(118deg, #814af6cc, #341e62cc);
  color: #eef0f1;
  font-weight: 600;
  font-family: "Barlow", sans-serif;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
}

.card-on-zoom-container-mb {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  -webkit-filter: hue-rotate(-280deg);
          filter: hue-rotate(-280deg);
}

.card-on-zoom-mb {
    margin: 0 auto;
  position: relative;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch;
  text-align: start;
}

.card-on-zoom-mb .deco-title {
  font-size: 5rem;
  font-weight: 600;
  color: #f2edfe;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3);
}
.about-content-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.about-cards-container {
  width: 1000px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 100px;
}

.about-card {
  position: relative;
  width: 40vw;
  height: 40vh;
  margin: 8px 16px;
  background: linear-gradient(118deg, #814AF6, #41257b);
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.about-card-img-container img{
  height: 175px;
  width: auto;
  margin-bottom: 30px;
}

.about-card-title-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-weight: 600;
  font-size: 1.4vw;
  font-family: 'Barlow', sans-serif;
  background: linear-gradient(118deg, #1b2631,#0b0f14);
  color: #e6dbfd;
  text-align: center;
  padding: 10px;
  border-radius: 0 0 7px 7px;
}


.about-content-container-tb {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  
  .about-cards-container-tb {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 100px;
  }
  
  .about-card-tb {
    position: relative;
    margin: 8px 16px;
    background: linear-gradient(118deg, #814AF6, #41257b);
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    width: 500px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  
  .about-card-img-container-tb img{
    height: 200px;
    width: auto;
    padding: 30px 20px;
    margin-bottom: 30px;
  }
  
  .about-card-title-container-tb {
    position: absolute;
    bottom: 0;
    width: 100%;
    font-weight: 600;
    font-size: 1.1em;
    font-family: 'Barlow', sans-serif;
    background: linear-gradient(118deg, #1b2631,#0b0f14);
    color: #e6dbfd;
    text-align: center;
    padding: 10px;
    border-radius: 0 0 7px 7px;
  }
  
  
.about-content-container-mb {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  
  .about-cards-container-mb {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 100px;
  }
  
  .about-card-mb {
    position: relative;
    margin: 8px 16px;
    background: linear-gradient(118deg, #814AF6, #41257b);
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    width: 300px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  
  .about-card-img-container-mb img{
    height: 150px;
    width: auto;
    padding: 30px 20px;
    margin-bottom: 30px;
  }
  
  .about-card-title-container-mb {
    position: absolute;
    bottom: 0;
    width: 100%;
    font-weight: 600;
    font-size: 1em;
    font-family: 'Barlow', sans-serif;
    background: linear-gradient(118deg, #1b2631,#0b0f14);
    color: #e6dbfd;
    text-align: center;
    padding: 10px;
    border-radius: 0 0 7px 7px;
  }
  
  
/* Global CSS for Projects Section */

.section-project-container {
  margin-top: 100px;
}

.project-assets-container {
  margin-top: 75px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.project-card {
  display: inline-block;
  position: relative;
  width: 400px;
  min-width: 400px;
  height: 400px;
  border-radius: 30px;
  overflow:hidden;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.3);
  margin: 20px;
}

.project-card-profile-img {
  width: 100%;
  height: 70%;
}

.project-card-description-bk {
  background: linear-gradient(118deg, #814AF6, #41257b);
  border-radius: 30px;
  position: absolute;
  top: 55%;
  left: -5px;
  height: 65%;
  width: 108%;
  -webkit-transform: skew(19deg, -9deg);
          transform: skew(19deg, -9deg);
}

.first .project-card-description-bk {
  background: linear-gradient(118deg, #00866D, #80c3b6);
}

.second .project-card-description-bk {
  background: linear-gradient(118deg, #2D4999, #981D81);
}

.third .project-card-description-bk {
  background: linear-gradient(118deg, #814AF6, #41257b);
}

.project-card-logo {
  height: 80px;
  width: 80px;
  border-radius: 25px;
  position: absolute;
  bottom: 30%;
  left: 30px;
  overflow:hidden;
  background: linear-gradient(118deg, #1b2631,#0b0f14);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.7);
}

.project-card-logo img {
  height: 100%;
}

.ravelin-logo-img {
  padding: 13px;
  padding-left: 15px;
}

.project-card-learn-more {
  border-radius: 50%;
  position: absolute;
  bottom: 35%;
  right: 30px;
  overflow:hidden;
  background: linear-gradient(118deg, #1b2631,#0b0f14);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  
}

.btn-learn-more {
  padding: 0;
}

.btn-learn-more i{
  color: #F8F9F9;
  font-size: 1.4em;
  margin: 10px 10px 8px 10px;
}

.project-card-description-container {
  position: relative;
  margin-left: 34px;
  margin-top: 15px;
}

.project-card-title{
  font-size: 1.6em;
  font-weight: 700;
  font-family: 'Barlow', sans-serif;
  color: #ECF0F1;
  text-align: start;
  margin-bottom: 10px;
}

.project-card-categories {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  text-align: start;
  -webkit-align-items: center;
          align-items: center;
}

.project-card-category{
  font-size: 1em;
  font-weight: 700;
  font-family: 'Barlow', sans-serif;
  color: #ECF0F1;
  background: linear-gradient(118deg, #1b2631,#0b0f14);
  padding: 7px 14px;
  border-radius: 7px;
  margin: 0 10px 0 0;
}
/* Global CSS for Projects Section */

.section-project-container-tb {
    margin-top: 0;
  }
  
/* Mobile CSS for Projects Section */

.section-project-container-mb {
    margin-top: 0;
}

.project-card-mb {
  display: inline-block;
  position: relative;
  width: 300px;
  min-width: 300px;
  height: 400px;
  border-radius: 30px;
  overflow:hidden;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.3);
  margin: 20px;
}
/* Global CSS for Investment Section */

.investment-assets-container {
  padding: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.investment-assets-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
  width: 60vw;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.asset-card {
  position: relative;
  width: 160px;
  height: 240px;
  margin: 30px 20px;
  border-radius: 7px;
  transition: 0.2s;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding-top: 24px;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.asset-img {
  width: 120px;
  height: 120px;
  padding: 10px;
  margin: 0 auto;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.asset-img-circle {
  width: 120px;
  height: 120px;
  padding: 10px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.details-container-for-asset-card {
  position: relative;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 10px;
}

.asset-card .asset-name {
  font-weight: 800;
  font-size: 1.2em;
  text-align: center;
  color: #cccccc;
  font-family: 'Barlow', sans-serif;
}

.asset-card .type-container {
  position: absolute;
  margin-top: 10px;
  bottom: 0;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
}


.asset-card p {
  color: #8149f5;
  font-family: 'Barlow', sans-serif;
  font-size: 1em;
  font-weight: 600;
  background: linear-gradient(118deg, #814AF6, #41257b);
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
  color: #eef0f1;
  border-radius: 7px;
  padding: 6px 12px;
}

.asset-card:hover p {
    background: #e6dbfd;
    color: #5a34ac;
}

.asset-card:hover {
  transition: 0.3s;
  border-radius: 7px;
  background: linear-gradient(118deg, #814AF6cc, #41257bcc);
}

.asset-card:hover img{
  -webkit-filter: none;
          filter: none;
}

.asset-card:hover .asset-name {
  color: #eef0f1;
}


/* Global CSS for Investment Section */

.investment-assets-container-tb {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin: 0;
  }
  
  .investment-assets-content-tb {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    text-align: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    width: 600px;
  }
  
  .asset-card-tb {
    position: relative;
    width: 150px;
    height: 220px;
    margin: 30px 20px;
    border-radius: 7px;
    transition: 0.2s;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    padding-top: 24px;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    transition: 0.3s;
    border-radius: 7px;
    background: linear-gradient(118deg, #814AF6cc, #41257bcc);
  }
  
  .asset-img-tb {
    width: 100px;
    height: 100px;
    padding: 10px;
    margin: 0 auto;
  }
  
  .asset-img-circle-tb {
    width: 100px;
    height: 100px;
    padding: 10px;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
  }
  
  .details-container-for-asset-card-tb {
    position: relative;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 10px;
  }
  
  .asset-card-tb .asset-name-tb {
    font-weight: 800;
    font-size: 1em;
    text-align: center;
    color: #eef0f1;
    font-family: 'Barlow', sans-serif;
  }
  
  .asset-card-tb .type-container-tb {
    position: absolute;
    margin-top: 10px;
    bottom: 0;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
  }
  
  
  .asset-card-tb p {
    font-family: 'Barlow', sans-serif;
    font-size: 1em;
    font-weight: 700;
    background: linear-gradient(118deg, #814AF6, #41257b);
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
    background: #e6dbfd;
    color: #5a34ac;
    border-radius: 7px;
    padding: 6px 12px;
  }
  
  
/* Global CSS for Investment Section */

.investment-assets-container-mb {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin: 0;
  }
  
  .investment-assets-content-mb {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    text-align: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }
  
  .asset-card-mb {
    position: relative;
    width: 150px;
    height: 220px;
    margin: 30px 20px;
    border-radius: 7px;
    transition: 0.2s;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    padding-top: 24px;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    transition: 0.3s;
    border-radius: 7px;
    background: linear-gradient(118deg, #814AF6cc, #41257bcc);
  }
  
  .asset-img-mb {
    width: 100px;
    height: 100px;
    padding: 10px;
    margin: 0 auto;
  }
  
  .asset-img-circle-mb {
    width: 100px;
    height: 100px;
    padding: 10px;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
  }
  
  .details-container-for-asset-card-mb {
    position: relative;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 10px;
  }
  
  .asset-card-mb .asset-name-mb {
    font-weight: 800;
    font-size: 1em;
    text-align: center;
    color: #eef0f1;
    font-family: 'Barlow', sans-serif;
  }
  
  .asset-card-mb .type-container-mb {
    position: absolute;
    margin-top: 10px;
    bottom: 0;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
  }
  
  
  .asset-card-mb p {
    font-family: 'Barlow', sans-serif;
    font-size: 1em;
    font-weight: 700;
    background: linear-gradient(118deg, #814AF6, #41257b);
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
    background: #e6dbfd;
    color: #5a34ac;
    border-radius: 7px;
    padding: 6px 12px;
  }
  
  
.section-team-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.core-team-container {
  padding-top: 100px;
}

.team-member {
  z-index: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 100px 0;
  width: 75vw;
}

.team-member-card {
  position: relative;
  width: 20rem;
  height: 40rem;
  margin: 50px 16px;
  background: linear-gradient(118deg, #1b2631,#0b0f14);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 20px;
}

.team-member-card:hover {
  background: linear-gradient(118deg, #814AF6, #341e62);
}

.team-member-card:hover .team-member-img-container img{
  background: #d9c9fc;
}

.team-member-card:hover .team-member-details-container h3 {
  color: #eef0f1;
}

.team-member-card:hover .team-member-details-container span {
  background: #e6dbfd;
  color: #5a34ac;
}

.team-member-card:hover .team-member-details-container p {
  color: #d9c9fc;
}

.team-member-details-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 3rem;
}

.team-member-img-container {
  position: absolute;
  width: 100%;
  top: -60px;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.team-member .team-member-img-container img {
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
  background: linear-gradient(118deg, #814af6, #673bc5);
  width: 120px;
  height: auto;
  border-radius: 50%;

  object-fit: cover;
}

.team-member .team-member-details-container h3 {
  color: #eef0f1;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 800;
  text-align: center;
  margin: 20px 0 10px;
  font-size: 1.4em;
  font-family: 'Barlow', sans-serif;
}

.team-member .team-member-details-container span {
  background: linear-gradient(118deg, #814af6, #673bc5);
  font-weight: 700;
  font-family: 'Barlow', sans-serif;
  font-size: 1.2em;
  border-radius: 7px;
  margin: 10px;
  color: #e6dbfd;
  padding: 8px 16px;
}

.team-member .team-member-details-container p {
  font-weight: 600;
  font-family: 'Barlow', sans-serif;
  font-size: 1.2em;
  border-radius: 7px;
  color: #e6dbfd;
  margin: 10px;
  padding: 20px;
  text-align: left;
}

.team-member .team-member-details-container .linkedn-container {
  position: absolute;
  bottom: 25px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.team-member .team-member-details-container .linkedn-container a{
  visibility: hidden;
  width: 2rem;
  height: 2rem;
  border-radius: 7px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.team-member-card:hover .linkedn-container a {
  visibility: visible;
  background: #e6dbfd;
}

.team-member-card:hover .linkedn-container a i {
  color: #5a34ac;
  font-size: 1em;
}

.section-team-container-tb {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .core-team-container-tb {
    padding-top: 100px;
  }
  
  .team-member-tb {
    z-index: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: 100px 30px;
  }
  
  .team-member-card-tb {
    position: relative;
    width: 300px;
    height: 700px;
    margin: 50px 16px;
    background: linear-gradient(118deg, #814af6, #341e62);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding-top: 60px;
    padding-bottom: 40px;
  }
  
  .team-member-details-container-tb {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 3rem;
  }
  
  .team-member-img-container-tb {
    position: absolute;
    width: 100%;
    top: -60px;
    left: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  
  .team-member-tb .team-member-details-container-tb {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column;
    }
  
  .team-member-tb .team-member-img-container-tb img {
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
    background: #d9c9fc;
    width: 120px;
    height: auto;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .team-member-tb .team-member-details-container-tb h3 {
    color: #eef0f1;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 800;
    text-align: center;
    margin: 20px 0 10px;
    font-size: 1.4em;
    font-family: "Barlow", sans-serif;
  }
  
  .team-member-tb .team-member-details-container-tb span {
    background: #e6dbfd;
    color: #5a34ac;
    font-weight: 700;
    font-family: "Barlow", sans-serif;
    font-size: 1.2em;
    border-radius: 7px;
    margin: 10px;
    padding: 8px 16px;
  }
  
  .team-member-tb .team-member-details-container-tb p {
    font-weight: 600;
    font-family: "Barlow", sans-serif;
    font-size: 1.2em;
    border-radius: 7px;
    color: #e6dbfd;
    margin: 10px;
    padding: 20px;
    text-align: left;
  }
  
  .team-member-tb .team-member-details-container-tb .linkedn-container-tb {
    position: absolute;
    bottom: 25px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  
  .team-member-card-tb .linkedn-container-tb a{
      visibility: visible;
      background: #e6dbfd;
      width: 2rem;
      height: 2rem;
      border-radius: 7px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
    }
    
    .team-member-card-tb .linkedn-container-tb a i {
      color: #5a34ac;
      font-size: 1em;
    }
  
.section-team-container-mb {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.core-team-container-mb {
  padding-top: 100px;
}

.team-member-mb {
  z-index: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 100px 30px;
}

.team-member-card-mb {
  position: relative;
  width: 100%;
  margin: 50px 16px;
  background: linear-gradient(118deg, #814af6, #341e62);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 60px;
  padding-bottom: 40px;
}

.team-member-details-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 3rem;
}

.team-member-img-container-mb {
  position: absolute;
  width: 100%;
  top: -60px;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.team-member-mb .team-member-details-container-mb {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

.team-member-mb .team-member-img-container-mb img {
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
  background: #d9c9fc;
  width: 120px;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
}

.team-member-mb .team-member-details-container-mb h3 {
  color: #eef0f1;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 800;
  text-align: center;
  margin: 20px 0 10px;
  font-size: 1.4em;
  font-family: "Barlow", sans-serif;
}

.team-member-mb .team-member-details-container-mb span {
  background: #e6dbfd;
  color: #5a34ac;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  font-size: 1.2em;
  border-radius: 7px;
  margin: 10px;
  padding: 8px 16px;
}

.team-member-mb .team-member-details-container-mb p {
  font-weight: 600;
  font-family: "Barlow", sans-serif;
  font-size: 1.2em;
  border-radius: 7px;
  color: #e6dbfd;
  margin: 10px;
  padding: 20px;
  text-align: left;
}

.team-member-mb .team-member-details-container-mb .linkedn-container-mb {
  position: absolute;
  bottom: 25px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.team-member-card-mb .linkedn-container-mb a{
    visibility: visible;
    background: #e6dbfd;
    width: 2rem;
    height: 2rem;
    border-radius: 7px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  
  .team-member-card-mb .linkedn-container-mb a i {
    color: #5a34ac;
    font-size: 1em;
  }

.footer-relative {
  position: relative;
}
.footer-social {
  bottom: 35px;
  width: 100%;
  height: 10vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.footer-social:before {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 35px;
  z-index: 1;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
.footer-social .color {
  position: absolute;
  -webkit-filter: blur(150px);
          filter: blur(150px);
}

.footer-social ul {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 !important;
  z-index: 2;
}
.footer-social ul li {
  position: relative;
  list-style: none;
  margin: 10px;
}
.footer-social ul li a {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  display: inline-block;
  border-radius: 7px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-decoration: none;
  color: #eef0f1;
  background: linear-gradient(#814af6cc, #4d2c94cc);
  font-size: 1.2em;
  box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  transition: 0.4s;
  overflow: hidden;
}
.footer-social ul li a:hover {
  background: #eef0f1;
  color: #814af6cc;
  border: none;
}

.footer-copyright {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  bottom: 15px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 7px;
  padding-top: 10px;
  padding-right: 15px;
  padding-bottom: 10px;
  padding-left: 15px;
}

.footer-copyright-text {
  font-size: 1.2em;
  color: #f2edfe;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  -webkit-filter: drop-shadow(0 0 1px #17020c);
          filter: drop-shadow(0 0 1px #17020c);
}

.area-container {
  display: -webkit-flex;
  display: flex;
  z-index: 2;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.text-content {
  text-align: center;
  font-family: "Barlow", sans-serif;
  margin: 50px 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.text-content h3 {
  font-size: 3.5em;
  font-weight: 900;
  color: #e6dbfd;
  font-family: "Barlow", sans-serif;
}

.text-content .dont-miss {
  border-radius: 7px;
  background: linear-gradient(118deg, #814af6cc, #41257bcc);
  text-align: center;
  color: #f2edfe;
  font-size: 2em;
  font-weight: 600;
  padding: 10px 20px;
  font-family: "Barlow", sans-serif;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);

}

.text-content .mail {
  margin-top: 10px;
  font-size: 1.6em;
  font-weight: 600;
  color: #eef0f1;
  font-family: "Barlow", sans-serif;
}

@-webkit-keyframes glowPink {
  0%,
  100% {
    box-shadow: 0 0 20px 5px #b392fa;
  }
  50% {
    box-shadow: 0 0 16px 8px #b392fa;
  }
}

@keyframes glowPink {
  0%,
  100% {
    box-shadow: 0 0 20px 5px #b392fa;
  }
  50% {
    box-shadow: 0 0 16px 8px #b392fa;
  }
}

.footer-relative-tb {
    position: relative;
  }
  .footer-social-tb {
    bottom: 35px;
    width: 100%;
    height: 10vh;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  .footer-social-tb:before {
    content: "";
    position: absolute;
    width: 100%;
    bottom: 35px;
    z-index: 1;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
  }
  .footer-social-tb .color {
    position: absolute;
    -webkit-filter: blur(150px);
            filter: blur(150px);
  }
  
  .footer-social-tb ul {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 !important;
    z-index: 2;
  }
  .footer-social-tb ul li {
    position: relative;
    list-style: none;
    margin: 10px;
  }
  .footer-social-tb ul li a {
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    display: inline-block;
    border-radius: 7px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-decoration: none;
    color: #eef0f1;
    background: linear-gradient(#814af6cc, #4d2c94cc);
    font-size: 1.2em;
    box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    transition: 0.4s;
    overflow: hidden;
  }
  
  .footer-copyright-tb {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 7px;
    padding-top: 10px;
    padding-right: 15px;
    padding-bottom: 10px;
    padding-left: 15px;
    margin-bottom: 25px;
  }
  
  .footer-copyright-text-tb {
    font-size: 1em;
    color: #f2edfe;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    font-family: "Barlow", sans-serif;
    font-weight: 500;
    -webkit-filter: drop-shadow(0 0 1px #17020c);
            filter: drop-shadow(0 0 1px #17020c);
  }
  
  .area-container-tb {
    display: -webkit-flex;
    display: flex;
    z-index: 2;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
  
  .text-content-tb {
    text-align: center;
    font-family: "Barlow", sans-serif;
    margin: 50px 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  
  .text-content-tb h3 {
    font-size: 3.5em;
    font-weight: 900;
    color: #e6dbfd;
    font-family: "Barlow", sans-serif;
  }
  
  .text-content-tb .dont-miss-tb {
    border-radius: 7px;
    background: linear-gradient(118deg, #814af6cc, #41257bcc);
    text-align: center;
    color: #f2edfe;
    font-size: 1.2em;
    font-weight: 600;
    padding: 10px 20px;
    font-family: "Barlow", sans-serif;
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
  }
  
  .text-content-tb .mail-tb {
    margin-top: 10px;
    font-size: 1.3em;
    font-weight: 600;
    color: #eef0f1;
    font-family: "Barlow", sans-serif;
  }
  
.footer-relative-mb {
  position: relative;
}
.footer-social-mb {
  bottom: 35px;
  width: 100%;
  height: 10vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.footer-social-mb:before {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 35px;
  z-index: 1;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
.footer-social-mb .color {
  position: absolute;
  -webkit-filter: blur(150px);
          filter: blur(150px);
}

.footer-social-mb ul {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 !important;
  z-index: 2;
}
.footer-social-mb ul li {
  position: relative;
  list-style: none;
  margin: 10px;
}
.footer-social-mb ul li a {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  display: inline-block;
  border-radius: 7px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-decoration: none;
  color: #eef0f1;
  background: linear-gradient(#814af6cc, #4d2c94cc);
  font-size: 1.2em;
  box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  transition: 0.4s;
  overflow: hidden;
}

.footer-copyright-mb {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 7px;
  padding-top: 10px;
  padding-right: 15px;
  padding-bottom: 10px;
  padding-left: 15px;
  margin-bottom: 25px;
}

.footer-copyright-text-mb {
  font-size: 1em;
  color: #f2edfe;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  -webkit-filter: drop-shadow(0 0 1px #17020c);
          filter: drop-shadow(0 0 1px #17020c);
}

.area-container-mb {
  display: -webkit-flex;
  display: flex;
  z-index: 2;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.text-content-mb {
  text-align: center;
  font-family: "Barlow", sans-serif;
  margin: 50px 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.text-content-mb h3 {
  font-size: 3.5em;
  font-weight: 900;
  color: #e6dbfd;
  font-family: "Barlow", sans-serif;
}

.text-content-mb .dont-miss-mb {
  border-radius: 7px;
  background: linear-gradient(118deg, #814af6cc, #41257bcc);
  text-align: center;
  color: #f2edfe;
  font-size: 1.2em;
  font-weight: 600;
  padding: 10px 20px;
  font-family: "Barlow", sans-serif;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
}

.text-content-mb .mail-mb {
  margin-top: 10px;
  font-size: 1.3em;
  font-weight: 600;
  color: #eef0f1;
  font-family: "Barlow", sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  -moz-tab-size: 4;
  tab-size: 4;
}


html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: #10171D;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1200 800'%3E%3Cdefs%3E%3CradialGradient id='a' cx='0' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23161f27'/%3E%3Cstop offset='1' stop-color='%23161f27' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='1200' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%230c1116'/%3E%3Cstop offset='1' stop-color='%230c1116' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='c' cx='600' cy='0' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23111820'/%3E%3Cstop offset='1' stop-color='%23111820' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='d' cx='600' cy='800' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%2310171D'/%3E%3Cstop offset='1' stop-color='%2310171D' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='e' cx='0' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%231B2631'/%3E%3Cstop offset='1' stop-color='%231B2631' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='f' cx='1200' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23080B0F'/%3E%3Cstop offset='1' stop-color='%23080B0F' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='1200' height='800'/%3E%3Crect fill='url(%23b)' width='1200' height='800'/%3E%3Crect fill='url(%23c)' width='1200' height='800'/%3E%3Crect fill='url(%23d)' width='1200' height='800'/%3E%3Crect fill='url(%23e)' width='1200' height='800'/%3E%3Crect fill='url(%23f)' width='1200' height='800'/%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}

.section-investment {
  padding-top: 100px;
  padding-bottom: 100px;
  background-size: cover;
}

code,
kbd,
samp,
pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, "Liberation Mono", Menlo,
    monospace; /* 1 */
  font-size: 1em; /* 2 */
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  margin: 0; /* 2 */
}

/**
Remove the inheritance of text transform in Edge and Firefox.
1. Remove the inheritance of text transform in Firefox.
*/

button,
select {
  /* 1 */
  text-transform: none;
}

*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  border-width: 0; /* 2 */
  text-decoration: inherit;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

video {
  max-width: 100%;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.container {
  width: 100%;
}

.section-about {
  padding-top: 100px;
  padding-bottom: 100px;
}

.section-projects {
  padding-top: 100px;
  padding-bottom: 100px;
}

.section-investments {
  padding-top: 100px;
  padding-bottom: 100px;
}

.section-team {
  padding-top: 100px;
  padding-bottom: 100px;
  background-attachment: fixed;
  background-size: cover;
}

.section-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  -webkit-justify-content: center;
          justify-content: center;
  padding-left: 4px;
  padding-right: 4px;
  z-index: 3;
}

.section-container-inside {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  margin-bottom: 24px;
  z-index: 3;
}

.section-content {
  width: 100%;
  padding: 4px;
}

.content-title {
  position: relative;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.content-title h2 {
  position: relative;
  font-size: 4em;
  color: #8149f5;
  font-weight: 400;
  margin-left: auto;
  margin-right: auto;
  font-family: "Oswald", sans-serif;
  -webkit-filter: drop-shadow(0 0 1px #8249f513) drop-shadow(0 0 2px #8249f534)
    drop-shadow(0 0 3px #8249f567);
          filter: drop-shadow(0 0 1px #8249f513) drop-shadow(0 0 2px #8249f534)
    drop-shadow(0 0 3px #8249f567);
  z-index: 3;
}

.castles-section-frame {
  position: relative;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
}

.frame-img {
  position: relative;
}


/* animates box shadow glow effect */
@-webkit-keyframes glow {
  0%,
  100% {
    box-shadow: 0 0 20px 5px #8149f5;
  }
  50% {
    box-shadow: 0 0 16px 8px #8149f5;
  }
}
@keyframes glow {
  0%,
  100% {
    box-shadow: 0 0 20px 5px #8149f5;
  }
  50% {
    box-shadow: 0 0 16px 8px #8149f5;
  }
}

.title-container {
  margin-top: 20px auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.first-title {
  font-size: 2.5em;
  color: #e6dbfd;
  font-weight: 800;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Barlow', sans-serif;
  text-align: center;
  margin-bottom: 20px;
  letter-spacing: 0.05em;
}

.second-title {
  font-size: 1.6em;
  padding: 10px 20px;
  border-radius: 7px;
  background: linear-gradient(118deg, #814AF6, #41257b);
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
  color: #eef0f1;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Barlow', sans-serif;
  text-align: center;
}

.description-container {
  text-align: center;
}

.description {
  margin-right: auto;
  margin-left: auto;
  font-family: 'Barlow', sans-serif;
  font-size: 1.6em;
  color: #eef0f1;
  width: 40vw;
  font-weight: 400;
  margin-top: 20px;
}

.title-container-tb {
  margin-top: 20px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.first-title-tb {
  font-size: 2em;
  color: #e6dbfd;
  font-weight: 800;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Barlow', sans-serif;
  text-align: center;
  margin-bottom: 20px;
  letter-spacing: 0.05em;
}

.second-title-tb {
  font-size: 1em;
  padding: 10px 20px;
  margin: 0 50px;
  border-radius: 7px;
  background: linear-gradient(118deg, #814AF6, #41257b);
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
  color: #eef0f1;
  font-weight: 600;
  font-family: 'Barlow', sans-serif;
  text-align: center;
}

.description-container-tb {
  text-align: center;
}

.description-tb {
  margin-right: auto;
  margin-left: auto;
  font-family: 'Barlow', sans-serif;
  font-size: 1em;
  width: 600px;
  color: #eef0f1;
  font-weight: 600;
  margin-top: 20px;
} 

.title-container-mb {
  margin-top: 20px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.first-title-mb {
  font-size: 2em;
  color: #e6dbfd;
  font-weight: 800;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Barlow', sans-serif;
  text-align: center;
  margin-bottom: 20px;
  letter-spacing: 0.05em;
}

.second-title-mb {
  font-size: 1.2em;
  padding: 10px 20px;
  margin: 0 50px;
  border-radius: 7px;
  background: linear-gradient(118deg, #814AF6, #41257b);
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
  color: #eef0f1;
  font-weight: 600;
  font-family: 'Barlow', sans-serif;
  text-align: center;
}

.description-container-mb {
  text-align: center;
}

.description-mb {
  margin-right: auto;
  margin-left: auto;
  font-family: 'Barlow', sans-serif;
  font-size: 1em;
  margin: 0 50px;
  color: #eef0f1;
  font-weight: 600;
  margin-top: 20px;
} 

/* Header */
.large-header {
	position: relative;
	width: 100%;
	background: #333;
	overflow: hidden;
	background-size: cover;
	background-position: center center;
	z-index: 1;
}

#large-header {
  background-color: #10171D;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1200 800'%3E%3Cdefs%3E%3CradialGradient id='a' cx='0' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23161f27'/%3E%3Cstop offset='1' stop-color='%23161f27' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='1200' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%230c1116'/%3E%3Cstop offset='1' stop-color='%230c1116' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='c' cx='600' cy='0' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23111820'/%3E%3Cstop offset='1' stop-color='%23111820' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='d' cx='600' cy='800' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%2310171D'/%3E%3Cstop offset='1' stop-color='%2310171D' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='e' cx='0' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%231B2631'/%3E%3Cstop offset='1' stop-color='%231B2631' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='f' cx='1200' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23080B0F'/%3E%3Cstop offset='1' stop-color='%23080B0F' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='1200' height='800'/%3E%3Crect fill='url(%23b)' width='1200' height='800'/%3E%3Crect fill='url(%23c)' width='1200' height='800'/%3E%3Crect fill='url(%23d)' width='1200' height='800'/%3E%3Crect fill='url(%23e)' width='1200' height='800'/%3E%3Crect fill='url(%23f)' width='1200' height='800'/%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}

.main-title {
	position: absolute;
	margin: 0;
	padding: 0;
	color: #f9f1e9;
	text-align: center;
	top: 50%;
	left: 50%;
	-webkit-transform: translate3d(-50%,-50%,0);
	transform: translate3d(-50%,-50%,0);
}

.demo-1 .main-title {
	text-transform: uppercase;
	font-size: 4.2em;
	letter-spacing: 0.1em;
}

.main-title .thin {
	font-weight: 200;
}

@media only screen and (max-width : 768px) {
	.demo-1 .main-title {
		font-size: 3em;
	}
}

.fab-container {
  visibility: hidden;
  position: fixed;
  bottom: 32px;
  right: 32px;
  z-index: 1;
}

.fab-content {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 7px;
  background: #4d2c94;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-decoration: none !important;
}

.fab-content i {
  font-size: 1.2em;
  color: #eef0f1 !important;
}

.hidden {
  visibility: hidden !important;
}

.show {
  visibility: visible !important;
}
