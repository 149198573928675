/* Mobile CSS for Projects Section */

.section-project-container-mb {
    margin-top: 0;
}

.project-card-mb {
  display: inline-block;
  position: relative;
  width: 300px;
  min-width: 300px;
  height: 400px;
  border-radius: 30px;
  overflow:hidden;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.3);
  margin: 20px;
}