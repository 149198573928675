.zoom {
    width: 100%;
    height: 100%;
    filter: hue-rotate(280deg);
    z-index: -1;
  }
  
  .card-on-zoom {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    filter: hue-rotate(-280deg);
  }
  
  .card-on-zoom h1 {
    width: 100%;
    font-size: calc(12px + 5vw);
    font-weight: 600;
    color: #f2edfe;
    text-shadow: 2px 7px 5px rgba(0,0,0,0.3);
  }
  
  .card-on-zoom p {
    width: 100%;
    font-size: calc(12px + 1.8vw);
    border-radius: 7px;
    padding: 10px 30px;
    background: linear-gradient(118deg, #814AF6cc, #341e62cc);
    color: #eef0f1;
    font-weight: 600;
    font-family: "Barlow", sans-serif;
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
  }
  
  a {
    text-decoration: inherit !important;
  }
  
  .arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    filter: hue-rotate(-280deg);
    margin-top: -60px;
  }
  
  .arrow i{
    padding-bottom: 100px;
    color: #eef0f1;
    font-size: 3em;
  }
  
  .arrow:hover i {
    color: #814af6;
  
  }
  
  .bounce {
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
  }
  
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }