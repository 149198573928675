/* Global CSS for Investment Section */

.investment-assets-container-tb {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  
  .investment-assets-content-tb {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    justify-content: space-around;
    width: 600px;
  }
  
  .asset-card-tb {
    position: relative;
    width: 150px;
    height: 220px;
    margin: 30px 20px;
    border-radius: 7px;
    transition: 0.2s;
    display: flex;
    justify-content: flex-start;
    padding-top: 24px;
    flex-direction: column;
    align-items: center;
    transition: 0.3s;
    border-radius: 7px;
    background: linear-gradient(118deg, #814AF6cc, #41257bcc);
  }
  
  .asset-img-tb {
    width: 100px;
    height: 100px;
    padding: 10px;
    margin: 0 auto;
  }
  
  .asset-img-circle-tb {
    width: 100px;
    height: 100px;
    padding: 10px;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
  }
  
  .details-container-for-asset-card-tb {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
  
  .asset-card-tb .asset-name-tb {
    font-weight: 800;
    font-size: 1em;
    text-align: center;
    color: #eef0f1;
    font-family: 'Barlow', sans-serif;
  }
  
  .asset-card-tb .type-container-tb {
    position: absolute;
    margin-top: 10px;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  
  .asset-card-tb p {
    font-family: 'Barlow', sans-serif;
    font-size: 1em;
    font-weight: 700;
    background: linear-gradient(118deg, #814AF6, #41257b);
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
    background: #e6dbfd;
    color: #5a34ac;
    border-radius: 7px;
    padding: 6px 12px;
  }
  
  